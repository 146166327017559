import React, { useState, useEffect } from "react";
import BlogPostCreator from "../../components/BlogPostCreator";
import ArticlePreview from "../../components/ArticlePreview";
import BlogPreview from "../../components/BlogPreview";
import BlogPostEditor from "../../components/BlogPostEditor";
import useArticles from "../../services/articleService";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    setMatches(media.matches);

    return () => media.removeEventListener("change", listener);
  }, [query]);

  return matches;
}

const UpdateArticlePage = () => {
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();

  const { fetchArticleBySlug, article, loading, markdown } = useArticles();

  // Gestion du slug avec sessionStorage et location.state
  const [slug, setSlug] = useState(() => {
    if (typeof window !== "undefined") {
      return location.state?.slug || sessionStorage.getItem("slug") || null;
    }
    return null;
  });

  // Met à jour sessionStorage dès que le slug est défini
  useEffect(() => {
    if (slug) {
      sessionStorage.setItem("slug", slug);
    }
  }, [slug]);

  const isLaptop = useMedia("(min-width: 1225px)");

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-editor-preview-container">
      <div className="blog-editor">
        <BlogPostEditor slug={slug} />
      </div>
      <div className="blog-preview">
        <BlogPreview />
        <ArticlePreview />
      </div>
    </div>
  );
};

export default UpdateArticlePage;
