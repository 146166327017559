import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import "../assets/style/style.css";
import ReactMde from "react-mde";
import MarkdownInfo from "./markdownInfo";
import { useArticleContext } from "../hooks/use-article-context";
import { fetchArticleBySlug } from "../services/articleService";

const BlogPostEditor = ({ slug }) => {
  const {
    updateArticle,
    setArticlePreview,
    fetchArticleBySlug,
    article,
    markdown,
    setMarkdown,
    deleteArticle,
  } = useArticleContext();
  const [selectedTab, setSelectedTab] = useState("write");
  const [formValues, setFormValues] = useState({
    title: "",
    author: "",
    date: "",
    category: "",
    slug: "",
    image: "",
  });

  // Effet pour récupérer l'article lors de la modification
  useEffect(() => {
    if (slug) {
      fetchArticleBySlug(slug);
    }
  }, [slug]);

  // Effet pour mettre à jour les valeurs du formulaire et le contenu markdown une fois l'article récupéré
  useEffect(() => {
    if (article) {
      setFormValues({
        title: article.title,
        author: article.author,
        date: article.date,
        category: article.category,
        slug: article.slug,
        image: article.image || "", // Assurez-vous que `article.image` existe
      });
    }
  }, [article]);

  // Effet pour mettre à jour l'aperçu de l'article
  useEffect(() => {
    setArticlePreview({
      ...formValues,
      content: markdown,
    });
  }, [formValues, markdown, setArticlePreview]);
  Showdown.extension("center-images", function () {
    return [
      {
        type: "output",
        regex: /<img(.*?)>/g,
        replace: `<div style="text-align: center;"><img $1 style="max-width:100%; height:auto; max-height:400px;" /></div>`,
      },
    ];
  });

  const customCommand = {
    name: "my-custom-command-indentation",
    icon: () => (
      <span role="img" aria-label="tabulation">
        <strong>--&gt;|</strong>
      </span>
    ),
    execute: (opts) => {
      opts.textApi.replaceSelection("&nbsp;&nbsp;&nbsp;&nbsp;");
    },
  };
  const customCommandH4 = {
    name: "my-custom-command-h4",
    icon: () => (
      <span role="img" aria-label="H4">
        <strong>H2</strong>
      </span>
    ),
    execute: (opts) => {
      opts.textApi.replaceSelection("#### ");
    },
  };

  const customCommandH5 = {
    name: "my-custom-command-h5",
    icon: () => (
      <span role="img" aria-label="H5">
        <strong>H3</strong>
      </span>
    ),
    execute: (opts) => {
      opts.textApi.replaceSelection("##### ");
    },
  };

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    literalMidWordUnderscores: true,
    extensions: ["center-images"],
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  // Ajoutez cette fonction au sein de BlogPostEditor
  const handleDelete = () => {
    const confirmation = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet article ?"
    );
    if (confirmation) {
      // Appel à la fonction de suppression via le contexte ou le service
      deleteArticle(article.slug);
    }
  };

  return (
    <div className="blog-editor-container">
      <h1 className="blog-editor-title">Modifier un article</h1>
      <Formik
        initialValues={formValues}
        validationSchema={Yup.object({
          title: Yup.string()
            .required("Titre requis")
            .max(50, "Le titre ne doit pas dépasser 50 caractères"),
          author: Yup.string().required("Auteur requis"),
          date: Yup.string().required("Date requise"),
          category: Yup.string().required("Catégorie requise"),
          slug: Yup.string().required("Slug requis"),
        })}
        onSubmit={(values) => {
          updateArticle({ ...values, content: markdown }); // Enregistrer l'article avec le contenu Markdown
        }}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form className="blog-form">
            <div className="form-group">
              <label>Titre</label>
              <Field
                name="title"
                type="text"
                className="field-disabled"
                disabled
              />
              <ErrorMessage name="title" component="div" className="error" />

              <label>Auteur</label>
              <Field
                name="author"
                type="text"
                className="field-disabled"
                disabled
              />
              <ErrorMessage name="author" component="div" className="error" />

              <label>Date</label>
              <Field name="date" type="date" />
              <ErrorMessage name="date" component="div" className="error" />

              <label>Catégorie</label>
              <Field as="select" name="category">
                <option value="">Sélectionner une catégorie</option>
                <option value="Application">Application</option>
                <option value="Divers">Divers</option>
                <option value="Events">Events</option>
                <option value="Playgrounds">Playgrounds</option>
                <option value="Streetball">Streetball</option>
              </Field>
              <ErrorMessage name="category" component="div" className="error" />

              <label>Image Principale</label>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const imageUrl = URL.createObjectURL(file);
                    setFieldValue("image", imageUrl);
                  }
                }}
              />
              <ErrorMessage name="image" component="div" />
              {values.image && (
                <div className="image-preview">
                  <img
                    src={values.image}
                    alt="Aperçu"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </div>
              )}

              <div className="label-container">
                <label>Contenu</label>
                <MarkdownInfo />
              </div>

              <ReactMde
                value={markdown}
                onChange={setMarkdown}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                disablePreview={true}
                commands={{
                  h4: customCommandH4,
                  indentation: customCommand,

                  h5: customCommandH5,
                }}
                toolbarCommands={[
                  [
                    "header",
                    "h4",
                    "h5",
                    "indentation",
                    "bold",
                    "italic",
                    "image",
                  ],
                ]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <button type="submit" className="btn">
                  Mettre à jour
                </button>

                <button
                  type="button"
                  className="btn-delete"
                  onClick={handleDelete}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BlogPostEditor;
